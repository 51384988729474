
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { WarmUpStage } from '@/models/tracing-staff/enums'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    const myOptions: StateOnboardingFilter[] = [
      {
        name: 'Todos', id: -1
      },
      {
        name: WarmUpStage.DONE, id: 0
      },
      {
        name: WarmUpStage.WILL_BE_CONTACTED, id: 1
      },
      {
        name: WarmUpStage.INCOMPLETE, id: 2
      }
    ]
    return {
      myOptions,
      optionSelected: ''
    }
  }
})
