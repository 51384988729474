
import { ICoursePlan } from '@/models/academic-definitions/interfaces/CoursePlan'
import { defineComponent, onBeforeMount, onMounted, PropType, ref, watch } from 'vue'
import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'
import OnboardingStatusSelector1 from '@/views/education/staff/components/OnboardingStatusSelector1.vue'
import OnboardingTable from '@/views/education/staff/components/OnboardingTable.vue'
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { StaffTracingAPI } from '@/api/staffTracing.api'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import router from '@/router'
import { CommonRouteNames } from '@/router/route-names'
import { WarmUpStage } from '@/models/tracing-staff/enums'
import CoursePlanSelectorCustom from '@/views/education/staff/components/CoursePlanSelectorCustom.vue'

export default defineComponent({
  components: {
    CoursePlanSelectorCustom,
    OnboardingTable,
    OnboardingStatusSelector1
  },
  props: {
    allCoursePlans: {
      type: Array as PropType<Array<ICoursePlan>>,
      required: true
    }
  },
  setup () {
    const nameFilter = ref<string>('')
    const stateFilter = ref<number>(-1)
    const coursesFilter = ref<CoursePlan[]>([])
    const staffTracing = ref<StaffTracingResponse>(new StaffTracingResponse(0, 0, []))
    const isLoading = ref(false)
    const errorRef = ref<null|unknown>(null)
    const searchTxt$ = new Observable<string>(subscriber => {
      watch(nameFilter, () => subscriber.next(nameFilter.value))
    })
    const myOptions: StateOnboardingFilter[] = [
      {
        name: WarmUpStage.DONE,
        id: 0
      },
      {
        name: WarmUpStage.WILL_BE_CONTACTED,
        id: 1
      },
      {
        name: WarmUpStage.INCOMPLETE,
        id: 2
      }
    ]
    const loadTracing = async (page: 0) => {
      try {
        isLoading.value = true
        staffTracing.value = new StaffTracingResponse(0, 0, [])
        staffTracing.value = await StaffTracingAPI.getStaffTracing({
          limit: 10,
          page: page + 1,
          role: 'teacher',
          name: nameFilter.value,
          courses: coursesFilter.value.map((course) => course.information.name),
          warmUp: stateFilter.value === -1 ? '' : myOptions.find((op) => op.id === stateFilter.value)!.name
        })
      } catch (error) {
        errorRef.value = error
        router.push({
          name: CommonRouteNames.ERROR_SCREEN,
          params: {
            error: JSON.stringify(error)
          }
        })
      } finally {
        isLoading.value = false
      }
    }
    const onPage = async (event: any) => {
      loadTracing(event.page)
    }
    onBeforeMount(() => {
      isLoading.value = true
    })
    onMounted(async () => {
      loadTracing(0)
    })
    watch(nameFilter, () => {
      searchTxt$.pipe(
        debounceTime(800),
        distinctUntilChanged()
      ).subscribe(() => loadTracing(0))
    })
    watch(stateFilter, () => {
      loadTracing(0)
      // name filter here
    })
    return {
      nameFilter,
      stateFilter,
      coursesFilter,
      staffTracing,
      isLoading,
      loadTracing,
      onPage
    }
  }
})
