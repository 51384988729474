export enum AssignationType {
    STARTED = 'Inicial',
    REPLACEMENT = 'Reemplazo',
  }

export enum AssignationOrigin {
    EXTERNAL = 'Externo',
    TUTOR = 'Tutor',
  }

export enum OnBoardingStage {
    VIEWED = 'Lo analizo',
    NOT_VIEWED = 'No lo analizo',
    VIEWED_BUT_WITH_DOUBTS = 'Lo analizo pero con dudas',
  }

export enum WarmUpStage {
    DONE = 'Hecho',
    WILL_BE_CONTACTED = 'Se contactará',
    INCOMPLETE = 'Incompleto',
  }

export enum ContentStage {
    VIEWED = 'Lo analizo',
    NOT_VIEWED = 'No lo analizo',
    VIEWED_BUT_WITH_DOUBTS = 'Lo analizo pero con dudas',
  }

export enum PlannedStage {
    DONE = 'Logrado',
    NOT_DELIVERED = 'No entregado',
    WITH_OBVSERVATIONS = 'Con observaciones',
  }

export enum StaffRole {
    teacher = 'teacher',
    tutor = 'tutor',
    coordinator = 'coordinator',
  }
