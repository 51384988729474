
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { WarmUpStage } from '@/models/tracing-staff/enums'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    selected: {
      type: Number,
      required: true
    }
  },
  data (prop) {
    const myOptions: StateOnboardingFilter[] = [
      {
        name: WarmUpStage.DONE, id: 0
      },
      {
        name: WarmUpStage.WILL_BE_CONTACTED, id: 1
      },
      {
        name: WarmUpStage.INCOMPLETE, id: 2
      }
    ]
    return {
      myOptions,
      optionSelected: myOptions.find(option => option.id === prop.selected)!.id
    }
  }
})
