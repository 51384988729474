
import { Column } from '@/models/components/tables/Column'
import { ColumnFields, OnboardingStageEnum } from '@/models/components/tables/enums'
import { OnBoardingStage, WarmUpStage } from '@/models/tracing-staff/enums'
import { StateOnboardingFilter } from '@/models/components/tables/Row'
import { formatDate } from '@/utils/formatDate'
import Calendar from 'primevue/calendar'
import { defineComponent, PropType } from 'vue'
import OnboardingSelector from '@/views/education/staff/components/OnboardingSelector.vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { StaffTracingAPI } from '@/api/staffTracing.api'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { useToast } from 'primevue/usetoast'
import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'

export default defineComponent({
  components: {
    OnboardingSelector,
    Calendar
  },
  props: {
    nameFilter: {
      type: String,
      required: true
    },
    stateFilter: {
      type: Number,
      required: true
    },
    coursesFilter: {
      type: Array as PropType<CoursePlan[]>,
      required: true
    },
    staffTracing: {
      type: StaffTracingResponse,
      required: true
    },
    showCol: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  methods: {
    optionSelected (options: StateOnboardingFilter[], name: string) {
      return options.find((opt) => opt.name === name)?.id
    }
  },
  setup (props) {
    const toast = useToast()
    const columns:Column[] = [
      { header: 'Nombre', field: ColumnFields.Person },
      { header: 'Curso', field: ColumnFields.CoursePlan },
      { header: 'Comisión', field: ColumnFields.Course },
      { header: 'Fecha de afiliación', field: ColumnFields.AfiliationDate },
      { header: 'Videos', field: ColumnFields.OnboardingVideos },
      { header: 'Etapas', field: ColumnFields.OnboardingStages }
    ]
    const myOptions: StateOnboardingFilter[] = [
      {
        name: WarmUpStage.DONE,
        id: 0
      },
      {
        name: WarmUpStage.WILL_BE_CONTACTED,
        id: 1
      },
      {
        name: WarmUpStage.INCOMPLETE,
        id: 2
      }
    ]
    const changeKickOff = async (data: StaffTracing, date: Date) => {
      try {
        await StaffTracingAPI.updateTracing({ ...data, kickOffDate: date })
        toast.add({
          summary: ToastSummaries.Success,
          detail: 'KickOff actualizado.',
          severity: ToastSeverities.Success,
          life: ToastLife.Default
        })
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: 'Error al actualizar, intente nuevamente.',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    const changeDate = (date: Date) => {
      return new Date(date).toLocaleDateString()
    }

    const changeWarmUp = async (data: StaffTracing, event: any) => {
      try {
        const newData = { ...data, warmupStage: myOptions.find(e => e.id === event.value)!.name! }
        if (newData.kickOffDate) {
          newData.kickOffDate = new Date(newData.kickOffDate!.toString().split('/').reverse().join('/'))
        }
        await StaffTracingAPI.updateTracing(newData)
        toast.add({
          summary: ToastSummaries.Success,
          detail: 'WarmUp actualizado.',
          severity: ToastSeverities.Success,
          life: ToastLife.Default
        })
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: 'Error al actualizar, intente nuevamente.',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }
    return {
      columns,
      ColumnFields,
      OnboardingStageEnum,
      OnBoardingStage,
      formatDate,
      changeWarmUp,
      changeDate,
      changeKickOff,
      myOptions
    }
  }
})
